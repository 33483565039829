const $ = jQuery;

export default function () {
	toggleEvents();
	toggleActiveEvent();
	loadMore();
	getSearchData();
}

let $currentSection;
let data = {
	s             : '',
	eventId       : '',
	currentPostIds: [],
	loadMore      : false,
};

function resetFilters() {
	data = {
		s             : '',
		eventId       : '',
		currentPostIds: [],
		loadMore      : false,
	};
}

function toggleEvents() {
	$('.js-custom-dropdown').on('click', function () {
		const $this = $(this);
		const $list = $this.find('.js-custom-dropdown--list');

		$this.toggleClass('active');
		$list.slideToggle(300);
	});

	$(document).on('click', function (e) {
		const $target = $(e.target);

		if (!$target.closest('.js-custom-dropdown').length) {
			$('.js-custom-dropdown').removeClass('active');
			$('.js-custom-dropdown--list').slideUp(300);
		}
	});
}

function toggleActiveEvent() {
	$('.js-custom-dropdown--list li').on('click', function () {
		const $this = $(this);
		$this.siblings().removeClass('active');
		$this.addClass('active');
		data.eventId = getEventId();
		$currentSection = $this.closest('section');
		getDocuments();
	});
}

function getEventId() {
	const $activeItem = $('.js-custom-dropdown--list li.active');
	return $activeItem.data('post-id');
}

function loadMore() {
	$('.documents-archive__load-more .c-button').on('click', function () {
		const $this = $(this);
		$currentSection = $this.closest('section');
		data.currentPostIds = getCurrentIds();
		data.loadMore = true;
		getDocuments();
	});
}

function getCurrentIds() {
	const $items = $currentSection.find('.document-card');
	let ids = [];

	$items.each(function () {
		const $this = $(this);
		ids.push($this.data('post-id'));
	});

	return ids;
}

function getSearchData() {
	let timer;
	$('#documents-archive-search input').on('keyup', function () {
		const $this = $(this);
		const value = $(this).val();
		clearTimeout(timer);

		if (value.length > 2 || value.length === 0) {
			timer = setTimeout(function () {
				$currentSection = $this.closest('section');
				data.s = value;
				data.currentPostIds = [];

				$('.js-custom-dropdown--list li').removeClass('active');
				$('.js-custom-dropdown--list li:first-child').addClass('active');

				getDocuments();
			}, 500);
		}
	});
}

function getDocuments() {
	let $postsContainer = $currentSection.find('.documents-archive__posts');
	let $loadMoreContainer = $currentSection.find('.documents-archive__load-more');

	$.ajax({
		url       : menaObject.ajaxurl,
		type      : 'GET',
		data      : {
			'action': 'documentsAjax',
			'data'  : data,
		},
		beforeSend: function () {
			$postsContainer.addClass('loading');
		},
		success   : function (response) {
			if (!response) return;
			response = JSON.parse(response);

			if (data.loadMore) {
				$postsContainer.append(response.html);
			} else {
				$postsContainer.html(response.html);
			}

			if (response.load_more) {
				$loadMoreContainer.show(200);
			} else {
				$loadMoreContainer.hide(200);
			}

			setTimeout(function() {
				$postsContainer.removeClass('loading');
			}, 200);
			resetFilters();
		},
		error     : function (error) {
			console.log(error);
		},
	});
}
