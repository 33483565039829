import * as echarts from 'echarts';

export default function charts() {
    let chartDoms = document.querySelectorAll('.js-charts--chart-container');

    chartDoms.forEach(chartDom => {
        let chartDataContainer = chartDom.parentElement.querySelectorAll('.js-charts--data span');
        let chartData = [];
        chartDataContainer.forEach(data => {
            let chartItem = {
                name: data.dataset.name,
                value: data.dataset.value,
                color: data.dataset.color,
            };
            chartData.push(chartItem);
        });

        let chartType = chartDom.dataset.type;
        if (chartType === 'doughnut') {
            doughnutChart(chartDom, chartData);
        } else if (chartType === 'bar') {
            barChart(chartDom, chartData);
        }
    });
}

function barChart(chartDom, chartData) {
    let myChart = echarts.init(chartDom);
    let option;
    let defaultColor = '#2C59C6';

    let preparedData = chartData.map(data => {
        return {
            value: data.value,
            name: data.name,
            label: {
                show: true,
                position: 'top',
                fontFamily: 'neue-haas-grotesk-text',
                fontSize: 16,
                lineHeight: 16,
                color: data.color ? data.color : defaultColor,
                rich: {
                    value: {
                        fontSize: 16,
                        lineHeight: 16,
                        fontWeight: 700,
                        fontFamily: 'neue-haas-grotesk-text',
                        align: 'left',
                        padding: [0, 0, 8, 4],
                        width: 45,
                    },
                    name: {
                        fontSize: 11,
                        lineHeight: 11,
                        fontFamily: 'neue-haas-grotesk-text',
                        width: 45,
                    },
                },
                formatter: function (params) {
                    const valueWords = data.name.split(' ');
                    const formattedValue = valueWords.join('\n  ');
                    return `{value|${data.value}%}\n {name|${formattedValue}}`
                },
            },
            itemStyle: {
                color: data.color ? data.color : defaultColor,
            },
        };
    });


    option = {
        grid: {
            top: 10,
            left: 60,
            right: '2%',
            bottom: 10,
        },
        xAxis: {
            type: 'category',
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
            axisLabel: {
                show: false,
            },
            splitLine: {
                show: true,
                lineStyle: {
                    color: '#DDDDDC',
                },
            },
        },
        yAxis: {
            type: 'value',
            min: 0,
            max: 100,
            interval: 10,
            axisLabel: {
                color: '#171717',
                formatter: '{value}%',
                fontSize: 16,
                lineHeight: 16,
                fontWeight: 700,
                fontFamily: 'neue-haas-grotesk-text',
            },
        },
        series: [
            {
                data: preparedData,
                type: 'bar',
                barWidth: 40,
                barGap: '30%',
            },
        ],
    };

    option && myChart.setOption(option);
}

function doughnutChart(chartDom, chartData) {
    let myChart = echarts.init(chartDom);
    let option;
    let defaultColor = '#2C59C6';

    let preparedData = chartData.map(data => {
        return {
            value: data.value,
            name: data.name,
            itemStyle: {
                color: data.color ? data.color : defaultColor,
            },
            label: {
                show: true,
                fontFamily: 'neue-haas-grotesk-text',
                fontSize: 16,
                lineHeight: 16,
                color: data.color ? data.color : defaultColor,
                rich: {
                    value: {
                        align: 'left',
                        fontSize: 16,
                        padding: [0, 0, 10, 0],
                    },
                    name: {
                        align: 'left',
                        fontSize: 16,
                    },
                },
                formatter: function (params) {
                    return `{value|${data.value}%}\n${data.name}`
                },
            },
        };
    });

    option = {
        tooltip: {
            trigger: 'item',
        },
        series: [
            {
                name: 'Access From',
                type: 'pie',
                radius: ['40%', '70%'],
                itemStyle: {
                    borderRadius: 8,
                    borderColor: '#fff',
                    borderWidth: 4,
                },
                label: {
                    show: true,
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 18,
                        fontWeight: 'bold',
                    },
                },
                labelLine: {
                    show: false,
                },
                data: preparedData,
            },
        ],
    };

    option && myChart.setOption(option);
}
