// import swiper and Pagination, Navigation module
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
// import styles
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/navigation';

export default function relatedSlider() {
	const slider = document.querySelector('.related__slider');
	if (!slider) {
		return;
	}

	// eslint-disable-next-line no-unused-vars
	const swiper = new Swiper('.related__slider', {
		slidesPerView: 1.1,
		spaceBetween: 16,
		modules: [Navigation],
		navigation: {
			nextEl: '.related__button--next',
			prevEl: '.related__button--prev',
		},
		breakpoints: {
			440: {
				slidesPerView: 1.5,
			},
			1024: {
				slidesPerView: 1.5,
			},
			1440: {
				slidesPerView: 2.5,
			},
		},


	});
}
