export default function team() {

	const teamFilters = document.querySelectorAll('.team__filters a');

	if(!team) return;

	teamFilters.forEach(filter => {
		filter.addEventListener('click', (e) => {
			e.preventDefault();

			const filterValue = filter.dataset.filter;
			const teamMembers = document.querySelectorAll('.team__card');

			teamFilters.forEach(filter => {
				filter.classList.remove('active');
			});

			teamMembers.forEach(member => {
				member.classList.remove('active');

				if (member.dataset.filter === filterValue) {
					member.classList.add('active');
				} else if (filter === 'all') {
					member.classList.add('active');
				}
			});
		});
	});
}