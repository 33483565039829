import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const $ = jQuery;
const $casesCards = $('.js-member-card');
const $select = $('.js-cases-map--select');
let cardsData = [];
let countryData = [];
let hoveredPolygonId = null;
let map = null;

/**
 * Cases map module
 */
export function casesMapModule() {
	const casesMap = document.getElementById('cases-map--map-box');
	if (casesMap) {
		getCountryData();
		getAllCaseCardsData();
		initCasesMap();
		casesSelect();
		setHoverStateForCasesCards();
		showFilters();
	}

}

let isoCodeCoordinates;

/**
 * Init mapbox map
 */
export async function initCasesMap() {
	const $sidebar = $('.cases-map__sidebar');

	// Init map
	mapboxgl.accessToken = 'pk.eyJ1Ijoib21lbi1lc2FpYyIsImEiOiJjbG9paGIyeTgxaGowMmlxcGN2djk1dDE0In0.v5d-PfC-4VDb_32bSBvkfg';
	map = new mapboxgl.Map({
		container  : 'cases-map--map-box',
		style      : 'mapbox://styles/omen-esaic/clqm7obc400km01r5e3vh97oh',
		center     : [24.2357, 55],
		zoom       : 3.7,
		pitch      : 0,
		bearing    : 0,
		interactive: false,
	});

	// Use Mapbox Geocoding API to get coordinates for each ISO code
	isoCodeCoordinates = await Promise.all(
		countryData.map(async (isoCode) => {
			isoCode = isoCode.toLowerCase();
			const response = await fetch(`https://api.mapbox.com/search/geocode/v6/forward?q=${isoCode}&country=${isoCode}&proximity=ip&types=country&access_token=${mapboxgl.accessToken}`);
			const data = await response.json();
			const coordinates = data.features.length > 0 ? data.features[0].geometry.coordinates : [0, 0]; // Default to [0, 0] if no coordinates found
			const wikiId = data.features.length > 0 ? data.features[0].properties.context.country.wikidata_id ?? null : null;
			isoCode = isoCode.toUpperCase();
			return {
				isoCode,
				coordinates,
				wikiId,
			};
		}),
	);


	// Add country polygons to map
	map.on('load', () => {
		map.addSource('countries', {
			type: 'vector',
			url: 'mapbox://mapbox.country-boundaries-v1',
		});

		map.addLayer({
			id: 'country-fills',
			type: 'fill',
			source: 'countries',
			'source-layer': 'country_boundaries',
			layout: {},
			paint: {
				'fill-color': [
					'case',
					['boolean', ['feature-state', 'hover'], false],
					'#2C59C6', // Color when hovered
					['boolean', ['feature-state', 'highlighted'], false],
					'rgba(44, 89, 198, 0.5)', // Color when highlighted
					'#DDE7F2', // Default color
				],
			},
		});

		map.addLayer({
			id: 'country-borders',
			type: 'line',
			source: 'countries',
			'source-layer': 'country_boundaries',
			layout: {},
			paint: {
				'line-color': '#2C59C6',
				'line-width': 0.2,
			},
		});

		map.on('mousemove', 'country-fills', (e) => {
			if (e.features.length > 0) {
				const feature = e.features[0];
				const isoCode = feature.properties.iso_3166_1;

				// Check if the feature has the highlighted state set to true
				const isHighlighted = map.getFeatureState({
					source: 'countries',
					sourceLayer: 'country_boundaries',
					id: feature.id,
				}).highlighted;

				if (isHighlighted) {
					if (hoveredPolygonId !== null && hoveredPolygonId !== feature.id) {
						// Restore the highlighted state and remove the hover state for the previously hovered country
						map.setFeatureState(
							{
								source: 'countries',
								sourceLayer: 'country_boundaries',
								id: hoveredPolygonId,
							},
							{ highlighted: true, hover: false }
						);
					}

					// Set highlighted to false and hover to true for the currently hovered country
					map.setFeatureState(
						{
							source: 'countries',
							sourceLayer: 'country_boundaries',
							id: feature.id,
						},
						{ highlighted: false, hover: true }
					);

					hoveredPolygonId = feature.id;
				}
			}
		});

		map.on('mouseleave', 'country-fills', () => {
			if (hoveredPolygonId !== null) {
				// Restore the highlighted state and remove the hover state for the previously hovered country
				map.setFeatureState(
					{
						source: 'countries',
						sourceLayer: 'country_boundaries',
						id: hoveredPolygonId,
					},
					{ highlighted: true, hover: false }
				);

				hoveredPolygonId = null;
			}
		});

		map.on('click', 'country-fills', (e) => {
			if (e.features.length > 0) {
				const isoCode = e.features[0].properties.iso_3166_1;
				const $option = $select.find('option[value="' + isoCode + '"]');
				if ($option.length > 0) {
					$select.val(isoCode).trigger('change');
				}
			}
		});
	});

	map.once('idle', () => {

		map.querySourceFeatures('countries', { sourceLayer: 'country_boundaries' }).forEach((feature) => {

			isoCodeCoordinates.forEach(({wikiId}) => {
				if (feature.properties.wikidata_id === wikiId) {
					hoveredPolygonId = feature.id;
				}
			});

			map.setFeatureState(
				{
					source: 'countries',
					sourceLayer: 'country_boundaries',
					id: hoveredPolygonId,
				},
				{ highlighted: true }
			);
		});
	});

	function createCustomMarker() {
		const mapPin = `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <circle cx="9" cy="9" r="9" fill="#F7F7F7"/>
                      <circle cx="9" cy="9" r="4.5" fill="#2C59C6"/>
                    </svg>`;

		let div = document.createElement('div');
		div.classList.add('cases-map__marker');
		div.innerHTML = mapPin;
		return div;
	}
}

/**
 * Select2 for cases map
 */
function casesSelect() {
	$select.select2({
		width     : '100%',
		allowClear: true,
	});

	$(document).on('select2:open', (e) => {
		const selectId = e.target.id;

		$('.select2-search__field[aria-controls=\'select2-' + selectId + '-results\']').each(function (
			key,
			value,
		) {
			value.focus();
		});
	});

	$select.on('change', function (e) {
		const $this = $(this);
		let country = $this.val();
		if (country) {
			country = country.toUpperCase();
			// center map on selected country
			// map.flyTo({
			// 	center: isoCodeCoordinates.find(({ isoCode }) => isoCode === country).coordinates,
			// });

			let wikiId = isoCodeCoordinates.find(({ isoCode }) => isoCode === country).wikiId;
			let hoveredPolygonId = null;
			map.querySourceFeatures('countries', { sourceLayer: 'country_boundaries' }).forEach((feature) => {
				if (feature.properties.wikidata_id === wikiId) {
					hoveredPolygonId = feature.id;
				}

				map.setFeatureState(
					{
						source     : 'countries',
						sourceLayer: 'country_boundaries',
						id         : feature.id,
					},
					{ hover: false },
				);
			});

			if (hoveredPolygonId !== null) {
				map.setFeatureState(
					{
						source     : 'countries',
						sourceLayer: 'country_boundaries',
						id         : hoveredPolygonId,
					},
					{ hover: true },
				);
			}
		}

		if (country === '' || country === null) {
			map.querySourceFeatures('countries', { sourceLayer: 'country_boundaries' }).forEach((feature) => {
				map.setFeatureState(
					{
						source     : 'countries',
						sourceLayer: 'country_boundaries',
						id         : feature.id,
					},
					{ hover: false },
				);
			});

			$casesCards.show();
			return;
		}

		$casesCards.hide();
		cardsData.forEach(function (value, key) {
			if (value.countries.includes(country)) {
				$casesCards.filter('[data-post-id="' + value.id + '"]').show();
			}
		});
	});
}

/**
 * Get all data from cards
 */
function getAllCaseCardsData() {
	$casesCards.each(function (key, value) {
			const $this = $(this);
			const countries = $this.data('countries');
			const countriesArray = countries.split(',');

			countriesArray.forEach(function (value, key) {
				countriesArray[key] = value.toUpperCase();
			});

			cardsData.push({
				id       : $this.data('post-id'),
				countries: countriesArray,
			});
		},
	);
}

/**
 * Set hover state for cases cards
 */
function setHoverStateForCasesCards() {
	$casesCards.on('mouseenter', function (e) {
		const $this = $(this);
		const countries = $this.data('countries');
		const countriesArray = countries.split(',');

		countriesArray.forEach(function (value, key) {
			countriesArray[key] = value.toUpperCase();
		});

		countriesArray.forEach(function (value, key) {
			if (countryData.includes(value)) {
				$('.cases-map__marker').filter('[data-iso-code="' + value + '"]').addClass('active');
			}
		});
	});

}

/**
 * Get all countries from select
 */
function getCountryData() {
	$select.find('option').each(function (key, value) {
		const $this = $(this);
		const countryCode = $this.val();

		if (countryCode) {
			countryData.push(countryCode);
		}
	});
}

function showFilters() {
	$('.js-cases-map--toggler').on('click', function (e) {
		e.preventDefault();
		$('.cases-map__sidebar').toggleClass('active');
	});
}
