import { archive } from '../components/archive';

const $ = jQuery;

let $currentSection;
let eventsArchiveData = {
	action        : 'eventsArchiveAjax',
	posts_per_page: 9,
	s             : '',
	terms         : {},
	paged         : 1,
};

export function eventsArchive() {
	terms();
	getSearchData();
	resetFilters();
	pagination();
}

function terms() {
	$('.js-archive-events--options li').on('click', function () {
		const $this = $(this);
		const taxonomy = $this.closest('.js-archive-events--options').data('taxonomy');
		const termId = $this.data('term-id');
		$currentSection = $this.closest('section');
		eventsArchiveData.terms = {};
		eventsArchiveData.paged = 1;

		if (taxonomy && termId) {
			eventsArchiveData.terms[taxonomy] = termId;
		}

		$('.js-archive-events--options li').removeClass('active');
		$this.addClass('active');
		archive($currentSection, eventsArchiveData);
	});
}

function getSearchData() {
	let timer;
	$('#event-archive-search input').on('keyup', function () {
		const $this = $(this);
		const value = $(this).val();
		clearTimeout(timer);

		if (value.length > 2 || value.length === 0) {
			timer = setTimeout(function () {
				$currentSection = $this.closest('section');
				eventsArchiveData.s = value;
				eventsArchiveData.paged = 1;
				archive($currentSection, eventsArchiveData);
			}, 500);
		}
	});
}

function resetFilters() {
	$(document).on('click', '.js-archive--reset', function (e) {
		e.preventDefault();
		const $this = $(this);
		$currentSection = $this.closest('section');

		eventsArchiveData = {
			action        : 'eventsArchiveAjax',
			posts_per_page: 9,
			s             : '',
			terms         : {},
			paged         : 1,
		};

		$('#event-archive-search input').val('');
		$('.js-events-archive--options li').removeClass('active');
		$('.js-events-archive--options li:first-child').addClass('active');
		archive($currentSection, eventsArchiveData);
	});
}

function pagination() {
	$(document).on('click', '.page-numbers', function (e) {
		e.preventDefault();
		const $this = $(this);
		const $parent = $this.closest('.js-archive-events--pagination');

		if ($parent.length === 0) {
			return;
		}

		$currentSection = $this.closest('section');
		let pageNumber;
		let $currentElement = $this;

		if ((!$this.hasClass('next')) && (!$this.hasClass('prev'))) {
			pageNumber = $this.text();
		} else {
			let activeNum = $this.closest('.pagination').find('.page-numbers.current');
			if ($this.hasClass('next')) {
				$currentElement = activeNum.next();
			}
			if ($this.hasClass('prev')) {
				$currentElement = activeNum.prev();
			}
			pageNumber = $currentElement.text();
		}

		pageNumber = parseInt(pageNumber);
		eventsArchiveData.paged = pageNumber;

		archive($currentSection, eventsArchiveData);
	});
}
