const $ = jQuery;

/**
 * Selectors
 * @type {postsContainer: string, postsLoadMore: string, resetFilters: string}
 */
let Selectors = {
	postsContainer: '',
	postsLoadMore : '',
	pagination    : '',
};

/**
 * All archive data
 * @type {{}}
 */
let ArchiveData = {};

/**
 * Is load more
 * @type {boolean}
 */
let isLoadMore = false;

/**
 * Archive posts
 * @param $sectionSelector - section selector
 * @param data - archive data
 * @param loadMore - is load more
 */
export function archive($sectionSelector, data, loadMore = false) {
	ArchiveData = data;
	isLoadMore = loadMore;
	prepareSelectors($sectionSelector);
	getPosts();
}

/**
 * Prepare selectors
 * @param $currentSection - section selector
 */
function prepareSelectors($currentSection) {
	Selectors.postsContainer = $currentSection.find('.js-archive--posts-container');
	Selectors.postsLoadMore = $currentSection.find('.js-archive--load-more');
	Selectors.pagination = $currentSection.find('.js-archive--pagination');
}

/**
 * Get posts
 */
function getPosts() {
	$.ajax({
		url       : menaObject.ajaxurl,
		type      : 'GET',
		data      : {
			'action': ArchiveData.action,
			'data'  : ArchiveData,
		},
		beforeSend: function () {
			Selectors.postsContainer.addClass('loading');
			$('html, body').animate({
				scrollTop: Selectors.postsContainer.offset().top - 300,
			}, 500);
		},
		success   : function (response) {
			if (!response) return;
			response = JSON.parse(response);

			if (isLoadMore) {
				Selectors.postsContainer.append(response.html);
			} else {
				Selectors.postsContainer.html(response.html);
			}

			if (response.loadMore) {
				Selectors.postsLoadMore.show(200);
			} else {
				Selectors.postsLoadMore.hide(200);
			}

			if (Selectors.pagination) {
				Selectors.pagination.html(response.pagination);
			}

			Selectors.postsContainer.removeClass('loading');
		},
		error     : function (error) {
			console.log(error);
		},
	});
}
